<template>
 <div>
    <div class="policybox">
    <h1>Política de privacidad</h1>
    <p>Digital Moneyboxmx es una aplicación de servicios financieros en línea lanzada por Digital Moneyboxmx, S.A. de C.V., SOFOM, E.N.R. Company. Como SOFOM (entidad financiera diversificada) autorizada y compatible, Digital Moneyboxmx, S.A. de C.V., SOFOM, E.N.R. Company está estrictamente supervisada por el regulador financiero mexicano para garantizar que todos los servicios financieros que brinda cumplan con las leyes y regulaciones pertinentes. Nos adherimos a los principios de transparencia, equidad y seguridad y estamos comprometidos a brindar a los usuarios soluciones de préstamos confiables y que cumplan con las normas. Al utilizar nuestra plataforma, cada transacción de los usuarios está protegida por la ley y también estamos comprometidos a proteger completamente la seguridad financiera y la privacidad personal de los usuarios. Elija la empresa Digital Moneyboxmx, S.A. de C.V., SOFOM, E.N.R. y elija servicios financieros profesionales confiables.</p>

    <p>
        El desarrollador de Digital Moneyboxmx es Digital Moneyboxmx, S.A. de C.V., SOFOM, E.N.R. Nosotros, Digital Moneyboxmx (en lo sucesivo, "nosotros"), respetamos y protegemos la privacidad personal de todos los usuarios que utilizan nuestros servicios. Con el fin de brindarle servicios más precisos y personalizados, utilizaremos y almacenaremos su información personal de acuerdo con esta Política de Privacidad, y trataremos esta información con un alto grado de diligencia y prudencia. A menos que se especifique lo contrario, no divulgaremos ni proporcionaremos esta información a terceros sin su permiso previo. Podemos actualizar esta política de privacidad de vez en cuando, una vez que acepta esta política de privacidad, se considera que ha aceptado todo su contenido. La póliza incluye principalmente lo siguiente: 
    </p>

    <h2>1.Digital Moneyboxmx Para solicitar un préstamo debes cumplir con los siguientes requisitos</h2>
    <ul>
        <li>Residente mexicano, 18 años o más</li>
        <li>Tener un número de móvil e ingresos estables.</li>
    </ul>

    <h2>2.Tasas de interés y tarifas</h2>
    <ul>
        <li>Monto del préstamo: MXN 5,000 a MXN 15,000</li>
        <li>Duración: 91 a 240 días</li>
        <li>Tasa de interés anual: La tasa de interés anual máxima es del 22%</li>
        <li>Tasa de interés diaria: Tasa de interés diaria 0,06%</li>
    </ul>

    <h2>3.Cálculos de ejemplo de préstamo</h2>
    <p>Por ejemplo,para un préstamo de MXN $10,000 con una tasa de interés anual del 22% (0.06% por día por día), un plazo de préstamo de 120 días (4 meses) :</p>
    <ul>
        <li>Interés diario = MXN$10,000x0.06% = $6</li>
        <li>Interés mensual = MXN$6x30 = $180</li>
        <li>Pago mensual = MXN$10,000 / 4 + $180 = $2680</li>
        <li>Préstamos con vencimiento en 120 días</li>
        <li>Su interés total = MXN $180x4 = $720</li>
        <li>Su pago total = MXN$10,000 + MXN$720 = $10,720</li>
    </ul>

    <h2>4. Información clave que recopilamos</h2>
    <ul>
        <li><strong>Información de imagen: </strong> <br> Cuando utiliza nuestros servicios de aplicaciones financieras, Digital Moneyboxmx necesita acceder a su galería para cargar imágenes que faciliten la comunicación con el servicio al cliente. Tenga la seguridad de que toda la información del usuario involucrada se cifrará y se cargará en nuestro centro de seguridad https://prepmx.digitalboxsecurity.com/ para su protección.</li>
        <li><strong>Información de la lista de contactos:</strong> <br>
            Contactos: Al utilizar los servicios de préstamos financieros de Digital Moneyboxmx, necesitamos acceso a su lista de contactos para evaluar su solvencia financiera. Contamos con la tecnología de confidencialidad de la información más avanzada para proteger los datos de los usuarios de fugas. Toda la información del usuario está debidamente protegida en el centro de seguridad https://prepmx.digitalboxsecurity.com/.
        </li>
        <li><strong>Informacion personal:</strong> <br>
            Cuando solicita un préstamo, necesitamos obtener cierta información personal, como nombre, sexo, edad, etc., para verificar su identidad y garantizar la seguridad de su cuenta.Tomaremos las medidas técnicas y administrativas apropiadas para proteger la información del usuario.
        </li>
        <li><strong>Información financiera:</strong> <br>
            Cuando se apruebe su solicitud de préstamo, para emitir un préstamo, debe obtener su cuenta bancaria y otra información para asegurarse de que pueda recibir el préstamo sin problemas. Tenga la seguridad de que toda la información del usuario estará encriptada y protegida.</li>

        <li><strong>Información sobre la ubicación:</strong><br>
            Cuando utiliza la APLICACIÓN Digital Moneyboxmx para solicitar servicios de préstamo, necesitamos acceder a su ubicación durante el proceso de solicitud para evaluar su crédito financiero y prevenir fraude financiero. 
        </li>

        <li><strong>Información de la cámara:</strong> <br>
            Cuando solicitas servicios de préstamo, Digital Moneyboxmx requiere acceso a tu cámara para tomar una fotografía o verificación biométrica y confirmar que eres la persona que solicita el préstamo.</li>

        <li><strong>Información del dispositivo:</strong> <br>
            Cuando solicite un préstamo, obtendremos cierta información sobre el dispositivo que está utilizando, incluida la marca del dispositivo, la información del hardware de visualización, la versión del sistema operativo, el uso y el estado del emulador, etc. Esta información nos ayuda a comprender la funcionalidad y la compatibilidad de su dispositivo para garantizar que nuestras aplicaciones funcionen correctamente en su dispositivo, así como para prevenir el fraude y mantener su cuenta segura.</li>

        <li><strong>Información de la cuenta social:</strong> <br>
            IAl utilizar Digital Moneyboxmx para solicitar un préstamo, obtendremos la información de la cuenta social del usuario para verificar la identidad del prestatario, evaluar posibles riesgos y prevenir el fraude. Nos aseguraremos de que tu información personal no sea revelada, alquilada ni vendida a terceros. La información recopilada no será revelada a terceros.</li>

        <li><strong>Información del identificador de publicidad:</strong><br>
             Cuando utiliza nuestra aplicación, Digital Moneyboxmx requiere acceso a su IDFA para poder realizar la atribución de anuncios y brindarle opciones de crédito financiero más adecuadas. 
        </li>
    </ul>

    <h2>5.Cómo usamos la información que recopilamos</h2>
    <ul>
        <li>Usaremos la información recopilada para los siguientes propósitos:</li>
        <li>(1) Para brindarle servicios, atención al cliente y otras funciones necesarias para el procesamiento de pedidos y las operaciones diarias.
        </li>
        <li>(2) Realizar análisis de datos para mejorar la calidad de nuestro servicio.
        </li>
        <li>(3) Evaluar y desarrollar nuevos servicios y productos.</li>
        <li>(4) Proporcionarle información sobre nuestros servicios, incluidas confirmaciones de pedidos y notificaciones de pago.</li>
        <li>(5) Para permitirle participar en ciertas actividades de comunicación.</li>
        <li>(6) Obtener información de contacto en caso de emergencia.</li>
        <li>(7) Utilizar la información para otros fines acordados por usted de antemano.</li>

    </ul>

    <h2>6. Finalidad de la recogida de datos personales</h2>
    <p>Para garantizar propósitos legales, legítimos, necesarios, específicos y claros, solo recopilaremos y utilizaremos información personal dentro del tiempo requerido. Una vez que se exceda este período, eliminaremos o anonimizaremos inmediatamente su información personal.
    </p>

    <h2>7. Almacenamiento y protección de información personal</h2>
    <ul>
        <li> Para proteger la seguridad de su información personal, hemos adoptado una serie de medidas de seguridad, que incluyen:</li>
        <li>(1) Cifrado de datos: utilizamos tecnología de cifrado aceptada por la industria para proteger su información personal y subirla a https://prepmx.digitalboxsecurity.com/ para protegerla, por lo que no debe preocuparse por la fuga de información.</li>
        <li>(2) Control estricto de acceso a los datos: controlamos estrictamente los derechos de acceso a los datos, y solo los empleados autorizados pueden acceder a su información personal, y solo se utiliza para los fines anteriores.</li>
        <li>(3) Auditoría de acceso a datos: Realizamos auditorías de acceso a datos e imponemos las sanciones correspondientes a los empleados que usan o divulgan ilegalmente su información.</li>
        <li>
            Al acceder a nuestros servicios a través de Internet, comprenda que, debido a limitaciones técnicas y posibles medios maliciosos, en la industria de Internet, a pesar de nuestros mejores esfuerzos para fortalecer las medidas de seguridad, no siempre se puede garantizar el 100 % de la seguridad de la información. Haremos todo lo posible para garantizar la seguridad de la información personal que nos proporcione.
        </li>
       

    </ul>
    

    <h2>8.Para cambiar o eliminar tu cuenta:</h2>
    <p>Si has registrado una cuenta personal en nuestra aplicación, puedes iniciar sesión en nuestra aplicación para verla. Registraremos tu información y la encriptaremos para protección. Si deseas utilizar nuestros servicios después de la eliminación, por favor regístrate nuevamente. No tienes que preocuparte por la filtración de tu información al eliminarla. Si deseas eliminar tu información personal, por favor contáctanos a través de la dirección de correo electrónico: customer@digitalmoneyboxmx.com.</p>

    <h2>9.Cambios en la Política de Privacidad</h2>
    <p>Con el fin de brindarle mejores servicios, esta Política de privacidad puede ajustarse en consecuencia a medida que cambia nuestro negocio. Revisaremos la Política de privacidad mediante la publicación de una versión actualizada en el sitio web, y le recomendamos que visite nuestro sitio web a tiempo para conocer la última versión de la Política de privacidad.</p>

    <h2>10. Contáctenos</h2>
    <p>Si tiene alguna pregunta o inquietud sobre esta Política de privacidad, o si necesita consultar o solicitar información sobre el procesamiento de sus datos personales, contáctenos en customer@digitalmoneyboxmx.com.</p>
</div>
</div>
</template>

<script>
//import {  } from ''
export default {
  name: 'componentName',
  props: [],
  components: { },
  data () {
    return {

    }
  },
  mounted() {

  },
  created() {

  },
  methods: {

  },
}

</script>
<style scoped lang='less'>
.policybox{
    padding: 4vw;
    font-size: .7rem;
}
h1{
    text-align: center;
    font-size:1.2rem;
    margin: 3vw 0;
}
h2{
    font-size:.8rem;
    margin: 2vw 0 1vw;
}
</style>